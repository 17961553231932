<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          POS <v-icon>mdi-chevron-right</v-icon> Reporte de ventas General
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#posTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <!-- <download-excel :name="'POS-SALES'" :before-generate="beforeDownload" :data="dataExcel"> -->
        <v-btn icon  @click="dialog = true">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      <!-- </download-excel> -->
    </v-toolbar>
    <v-row>
      <v-col>
        <OrderTable ref="posTable"/>
      </v-col>
    </v-row>
    <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>¿Desea incluir los items de las órdenes?</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-radio-group
            v-model="includeItems"
            column
          >
            <v-radio
              label="Sí"
              :value="true"
            ></v-radio>
            <v-radio
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>
          <v-row v-if="includeItems">
            <!-- <v-col>
                <v-autocomplete
                  append-icon="mdi-chevron-down"
                  :items="fieldsList"
                  v-model="selectedFields"
                  label="Seleccione campos"
                  clearable
                  multiple
                >
                </v-autocomplete>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="allFields"
                label="Todos los campos"
              ></v-checkbox>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-4 pt-4 justify-space-around flex">
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <download-excel :name="'POS-SALES'" :before-generate="getReport" :data="dataExcel">
            <v-btn
              color="primary"
              text
              :loading="loading"
            >
              Generar
            </v-btn>
          </download-excel>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  </v-container>
</template>

<script>
import OrderTable from '@/components/reports/pos/SalesTable.vue'
import apiOrder from "@/api/externos/orderPos";
export default {
  components: { OrderTable },
  data: () => ({
    dataExcel: [],
    includeItems: false,
    dialog: false,
    fieldsList: [{text: "Nombre", value: "item.name"}, {text: "Distribución", value: "costCenterName"}, {text: "Cantidad", value: "qty"},
                 {text: "Costo", value: "costItem"}, {text: "Precio", value: "unitPrice"}, {text: "Cupón", value: "discount.amount"},
                 {text: "Tipo impuesto", value: "item.taxType"}, {text: "Impuesto monto", value: "tax"}, {text: "Monto por adición", value: "extras"},
                 {text: "Cortesía", value: "gift"}, {text: "Nota", value: "note"}
    ],
    selectedFields: [],
    allFields: false,
    loading: false
  }),
  methods: {
    beforeDownload() {
      this.dataExcel = this.mapOrders(this.$refs.posTable.dataOrder)
    },
    openDrawer() {
      this.$refs.posTable.openDrawer()
    },
    mapOrders(data) {
      const body = data.map(e=>{
        return {
          "Orden N°": e.invoice,
        "Negocio": e.business?.name,
        "Cliente": e.client,
        "Empleado": e.employeeName,
        "Orden cerrada por:": e.closedBy ? `${e.closedBy.name} ${e.closedBy.lastName}`: "",
        "Fecha de creación": e.createdAt,
        "Hora de creación": e.createdHour,
        "Fecha de cierre": e.updatedAt,
        "Hora de cierre": e.updatedHour,
        "Subtotal": ((Number(e.total) + Number(e?.discount?.amount ?? 0) -
         Number(e.tipAmount ?? 0) - Number(e.taxAmount ?? 0)).toFixed(2)).replace('.', ','),
        "Impuestos": e.taxAmount?.toString().replace('.', ','),
        "Descuento": e.discount?.amount?.toString().replace('.', ','),
        "% Desc": Number(e.percDiscount),
        "Propina": e.tipAmount?.toString().replace('.', ','),
        "Total": e.total?.toString().replace('.', ','),
        "Método de pago": e?.methodPay?.method,
        "N° de Personas": e.personOrder,
        "Nota": e.note,
        "REF Reserva": e.booking?.ref,
        "Dueño Reserva": e.booking?.user ? `${e.booking?.user?.name} ${e.booking?.user?.lastName}`: null,
        "Por app": e.byClientApp ? "SÍ" : "NO",
        }
      })
      return body
    },
    mapOrdersWithItems(data) {
      const body = data.map(e=>{
        return {
          "Orden N°": e.invoice,
          "Negocio": e.business?.name,
          "Item": e.item.name,
          "Item Cantidad": e.qty,
          "Item Costo por unidad": e?.costItem?.toString().replace('.', ',') ?? '',
          "Item Precio unidad": e.unitPrice.toString().replace('.', ','),
          "Item Cupón Monto": e.discount?.amount?.toString().replace('.', ',') ?? 0,
          "Item Tipo impuesto": e.item.taxType,
          "Item Impuesto unidad": e.tax ? e.tax?.toFixed(2)?.toString().replace('.', ',') : 0,
          "Item Monto por adición": e.extras?.toString().replace('.', ','),
          "Item Cortesía": e.gift ? "Sí: " : "No",
          "Item Nota": e.note,
          "Cliente": e.client,
          "Empleado": e.employeeName,
          "Orden cerrada por:": e.closedBy ? `${e.closedBy.name} ${e.closedBy.lastName}`: "",
          "Orden Fecha de creación": e.createdAt,
          "Orden Hora de creación": e.createdHour,
          "Orden Fecha de cierre": e.updatedAt,
          "Orden Hora de cierre": e.updatedHour,
          "Orden Subtotal": ((Number(e.total) + Number(e?.discount?.amount ?? 0) -
          Number(e.tipAmount ?? 0) - Number(e.taxAmount ?? 0)).toFixed(2)).replace('.', ','),
          "Orden Impuestos": e.taxAmount?.toString().replace('.', ','),
          "Orden Descuento": e.discount?.amount?.toString().replace('.', ','),
          "Orden % Desc": Number(e.percDiscount),
          "Orden Propina": e.tipAmount?.toString().replace('.', ','),
          "Orden Total": e.total?.toString().replace('.', ','),
          "Orden Método de pago": e?.methodPay?.method,
          "Orden N° de Personas": e.personOrder,
          "Orden Nota": e.note,
          "Orden REF Reserva": e.booking?.ref,
          "Orden Dueño Reserva": e.booking?.user ? `${e.booking?.user?.name} ${e.booking?.user?.lastName}`: null,
          "Orden Por app": e.byClientApp ? "SÍ" : "NO",
        }
      })
      return body
    },
    async getReport() {
      try {
        this.loading = true
        if (this.includeItems) {
          const prueba = await this.$refs.posTable.getDataWithItems()
          this.dataExcel = this.mapOrdersWithItems(prueba)
        }
        else {
          this.beforeDownload()
        }
        this.loading = false
        this.dialog = false
      } catch (err) {
        console.log(err, 'error')
        this.loading = false
      }
    },
  
  },
  watch: {
    allFields(value) {
      if (value) this.selectedFields = this.fieldsList.map(field => field.value)
      else this.selectedFields = []
    },
  },
}
</script>