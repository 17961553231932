<template>
  <div>
    <v-row justify="center" align="center" v-if="this.chartData1.datasets.length>0">
      <v-col cols="12" md="3" justify="center" align="center">
        <h6>Total: {{totalSales}}</h6>
        <Pie
          :chart-options="chartOptions"
          :data="chartData1"
          :styles="styles"
        />
      </v-col>
       <v-col cols="12" md="3" justify="center" align="center">
        <h6>Total: {{countOrder}}</h6>
        <Pie
          :chart-options="chartOptions"
          :data="chartData2"
          :styles="styles"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="PosSalesHeaders"
          :items="dataOrder"
          :options.sync="options"
          :server-items-length="totalOrder"
          :loading="loading"
          class="elevation-0"
        ></v-data-table>
      </v-col>
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
          <v-toolbar dark class="elevation-0 secondary">
            <v-toolbar-title> <strong>Filtros y búsquedas</strong></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon>mdi-filter-cog</v-icon>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col class="pb-0">
                <v-subheader class="secondary--text" style="height: auto;">
                  Rango de fecha
                  <v-spacer></v-spacer>
                </v-subheader>
                <v-dialog ref="modalCheckin" v-model="modalCheckin" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="rangeDate" readonly v-bind="attrs" v-on="on" hide-details dense clearable placeholder="26/09/2024"></v-text-field>
                  </template>
                  <v-date-picker range v-model="rangeDate" @input="handleDateInput"></v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <v-subheader class="secondary--text" style="height: auto;">Cliente</v-subheader>
                <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-account" v-model="usersSelected" @update:search-input="onSearchUser" :items="users" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Cliente" multiple clearable chips deletable-chips small-chips :hint="'John doe'">
                  <template v-slot:item="{ item }">
                    <!-- <v-list-item-avatar color="grey lighten-3">
                      <v-img :src="item.photo"></v-img>
                    </v-list-item-avatar> -->
                    <v-list-item-content>
                      <v-list-item-title >{{ item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.documentID }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Negocio</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-store" v-model="businessSelected" @update:search-input="onSearchBusiness" :items="business" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Negocio" multiple clearable chips deletable-chips small-chips>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title >{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
            <v-row>
              <v-col>
                <div style="height: 64px;"></div>
              </v-col>
            </v-row>
            <v-footer fixed padless>
              <v-row>
                <v-col class="text-center" cols="12" >
                  <v-toolbar  class="elevation-0" color="grey lighten-5">
                    <v-btn @click="getDataTable" class="elevation-0 primary--text" text>Aplicar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="elevation-0" @click="clean" text>Limpiar</v-btn>
                  </v-toolbar>
                </v-col>
              </v-row>
            </v-footer>
          </v-container>
        </v-navigation-drawer>
    </v-row>
  </div>
</template>

<script>
  import { PosSalesHeaders } from '@/constants/tableheaders'
  import apiOrder from "@/api/externos/orderPos";
  import apiUser from '@/api/user'
  import moment from 'moment'
  import { setCurrencyFormat } from '@/utils/index'
  import apiBusiness from '@/api/business'
  import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
  ArcElement,
} from 'chart.js'
import { Line as LineChartGenerator, Pie } from 'vue-chartjs'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, PointElement, LinearScale, LineElement, ArcElement)

  export default {
    components: {
      LineChartGenerator, Pie
    },
    props: {
      styles: {
      type: Object,
      default: () => {}
    },
    },
    data: () => ({
      PosSalesHeaders,
      totalOrder: 0,
      dataOrder: [],
      loading: false,
      options: {},
      rangeDate: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      drawerRight: false,
      modalCheckin: false,
      modalCheckout: false,
      usersSelected: [],
      users: [],
      businessSelected: [],
      business: [],
      chartData1: {
      labels: [],
      datasets: []
      },
      labels1: [],
      chartData2: {
      labels: [],
      datasets: []
      },
      labels2: ['Cantidad de ordenes por App Móvil', 'Cantidad de ordenes por App Desktop'],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      totalSales: 0,
      countOrder: 0
      }),
    watch: {
      options: {
        handler () {
          this.getDataTable()
        },
        deep: true,
      },
    },
    created() {
      this.getBusiness()
      this.getUsers()
    },
    methods: {
    handleDateInput(dates) {
      if (dates && dates.length === 2) this.modalCheckin = false
    },
      //obtener datos para tabla
    async getDataTable() {
      try {
        let dates = {}
        this.dataOrder = []
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (this.rangeDate.length > 1)
          dates = { initDate: this.rangeDate[0], endDate: this.rangeDate[1] }
        else if (this.rangeDate.length == 1)
          dates = {
            initDate: this.rangeDate[0],
            endDate: this.rangeDate[0],
          }
        const direction = sortDesc[0] ? "asc" : "desc"
        let field = sortBy[0]
        if (sortBy[0] == "employeeName") field = "employee.name"
        else if (sortBy[0] == "totalPay") field = "total"
        else if (sortBy[0] == "tipAmountPay") field = "tipAmount"
        else if (sortBy[0] == "discountAmount") field = "discount.amount"
        else if (sortBy[0] == "percDiscount") field = "discount.perc"
        const query = {
          sort:
            sortBy.length > 0
              ? [
                  {
                    direction: direction,
                    field: field,
                    sortField: field,
                  },
                ]
              : null,
          page: page,
          per_page: itemsPerPage,
          initDate: dates.initDate,
          endDate: dates.endDate,
          clients: this.usersSelected,
          business: this.businessSelected
        }
        this.loading = true
        this.getDataGraphic()
        const {data: dataOrder} = await apiOrder.getSales(query)
        this.dataOrder = dataOrder.data

        this.dataOrder.forEach((j) => {
          let client = null
          const date = j.updateHistory.find(e=>e.status == "closed")?.updatedAt
          if (!j.booking) {
            client = !j?.user?.isCompany
              ? `${j?.user?.name?.toUpperCase()} ${j?.user?.lastName?.toUpperCase()}`
              : j?.user?.companyName.toUpperCase()
          } else {
            if (!j.user)
                client = j.booking.user
                ? `${j?.booking?.user?.name?.toUpperCase()} ${j?.booking?.user?.lastName?.toUpperCase()}`
                : null
            else
              client = !j?.user?.isCompany
                ? `${j?.user?.name?.toUpperCase()} ${j?.user?.lastName?.toUpperCase()}`
                : j?.user?.companyName.toUpperCase()
          }
          const discount = j.discount ? j.discount.amount : 0
          const perc = j.discount?.perc
          j.client = client
          j.discountAmount = setCurrencyFormat(discount)
          j.percDiscount = perc ?? null
          j.createdHour = moment(j.createdAt).format('hh:mm a')
          j.updatedHour = moment(j.updatedAt).format('hh:mm a')
          j.closeHour = date ? moment(date).format('hh:mm a') : ""
          j.createdAt = moment(j.createdAt).format('YYYY-MM-DD')
          j.updatedAt = moment(j.updatedAt).format('YYYY-MM-DD')
          j.closeDate = date ? moment(date).format('YYYY-MM-DD') : ""
          j.totalPay = setCurrencyFormat(j.total)
          j.tipAmountPay = setCurrencyFormat(j.tipAmount)
          j.employeeName = j.employee?.name ? `${j.employee.name} ${j.employee.lastName}` : null
        })
        this.totalOrder = dataOrder.links.pagination.total
        this.loading = false
      } catch (error) {
        console.log(error, "error")
        this.loading = false
      }
    },
    openDrawer() {
      this.drawerRight = true
    },
    clean() {
      this.rangeDate = []
      this.usersSelected = []
      this.businessSelected = []
    },
    async getUsers(search) {
      try {
        this.loading = true
        const { data: { data: users } } = await apiUser.table({ itemsPerPage: 10, page: 1, search })
        const auxSelecteds = this.users.filter(v => this.usersSelected.some(e => v.value === e))
        this.users = users.map(v => ({ text: `${v.name} ${v.lastName}`, value: v._id, email: v.email,  data: v }))
        this.users = [...this.users, ...auxSelecteds]
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    onSearchUser(search) {
      this.getUsers(search)
    },
    async getDataGraphic() {
      try {
        let dates = {}
        this.totalSales = 0
        this.countOrder = 0
        this.chartData2 = {
          labels: [],
          datasets: []
        }
        this.chartData1 = {
          labels: [],
          datasets: []
        }
        if (this.rangeDate.length > 1)
          dates = { initDate: this.rangeDate[0], endDate: this.rangeDate[1] }
        else if (this.rangeDate.length == 1)
          dates = {
            initDate: this.rangeDate[0],
            endDate: this.rangeDate[0],
          }
        const query = {
          initDate: dates.initDate,
          endDate: dates.endDate,
          clients: this.usersSelected,
          business: this.businessSelected
        }
        const {data: dataOrder} = await apiOrder.getSalesGeneral(query)
        // console.log(dataOrder,'dataOrder');
        if(Object.values(dataOrder.data).some(value => value !== 0)) {
          this.totalSales = setCurrencyFormat(dataOrder.data.acumApp + dataOrder.data.acumDesktop)
          this.countOrder = dataOrder.data.countApp + dataOrder.data.countDesktop
          this.labels1 = [`% Ingresos por App Móvil (${setCurrencyFormat(dataOrder.data.acumApp)})`, `% Ingresos por App Desktop (${setCurrencyFormat(dataOrder.data.acumDesktop)})`],
          this.chartData1 = {
            labels: this.labels1,
            datasets: [{
              backgroundColor: ['#B68C4F', '#E0CAAB'],
              data: [dataOrder.data.percApp, dataOrder.data.percDesktop]
            }]
          }
          this.chartData2 = {
            labels: this.labels2,
            datasets: [{
              backgroundColor: ['#B68C4F', '#E0CAAB'],
              data: [dataOrder.data.countApp, dataOrder.data.countDesktop]
            }]
          }
        }
      } catch (error) {
        console.log(error, "error")
      }
    },

     async getBusiness(search) {
      try {
        this.loading = true
        let { data: { data: business } } = await apiBusiness.table({ itemsPerPage: 500, page: 1, search })
        business = business.filter(e=> e.business && !e.business.service)
        const auxSelecteds = this.business.filter(v => this.businessSelected.some(e => v.value === e))
        // console.log(business, 'business');
        this.business = business.map(v => ({ text: v.name, value: v._id }))
        this.business = [...this.business, ...auxSelecteds]
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    onSearchBusiness(search) {
      this.getBusiness(search)
    },

    //obtener datos para tabla
    async getDataWithItems() {
        try {
          let dates = {}
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          if (this.rangeDate.length > 1)
            dates = { initDate: this.rangeDate[0], endDate: this.rangeDate[1] }
          else if (this.rangeDate.length == 1)
            dates = {
              initDate: this.rangeDate[0],
              endDate: this.rangeDate[0],
            }
          const direction = sortDesc[0] ? "asc" : "desc"
          let field = sortBy[0]
          if (sortBy[0] == "employeeName") field = "employee.name"
          else if (sortBy[0] == "totalPay") field = "total"
          else if (sortBy[0] == "tipAmountPay") field = "tipAmount"
          else if (sortBy[0] == "discountAmount") field = "discount.amount"
          else if (sortBy[0] == "percDiscount") field = "discount.perc"
          const query = {
            sort:
              sortBy.length > 0
                ? [
                    {
                      direction: direction,
                      field: field,
                      sortField: field,
                    },
                  ]
                : null,
              page: page,
              per_page: itemsPerPage,
              initDate: dates.initDate,
              endDate: dates.endDate,
              clients: this.usersSelected,
              business: this.businessSelected
          }
          const {data: dataOrder} = await apiOrder.getSalesWithItems(query)
          dataOrder.data.forEach((j) => {
          let client = null
          const date = j.updateHistory.find(e=>e.status == "closed")?.updatedAt
          if (!j.booking) {
            client = !j?.user?.isCompany
              ? `${j?.user?.name?.toUpperCase()} ${j?.user?.lastName?.toUpperCase()}`
              : j?.user?.companyName.toUpperCase()
          } else {
            if (!j.user)
                client = j.booking.user
                ? `${j?.booking?.user?.name?.toUpperCase()} ${j?.booking?.user?.lastName?.toUpperCase()}`
                : null
            else
              client = !j?.user?.isCompany
                ? `${j?.user?.name?.toUpperCase()} ${j?.user?.lastName?.toUpperCase()}`
                : j?.user?.companyName.toUpperCase()
          }
          const discount = j.discount ? j.discount.amount : 0
          const perc = j.discount?.perc
          j.client = client
          j.discountAmount = setCurrencyFormat(discount)
          j.percDiscount = perc ?? null
          j.createdHour = moment(j.createdAt).format('hh:mm a')
          j.updatedHour = moment(j.updatedAt).format('hh:mm a')
          j.closeHour = date ? moment(date).format('hh:mm a') : ""
          j.createdAt = moment(j.createdAt).format('YYYY-MM-DD')
          j.updatedAt = moment(j.updatedAt).format('YYYY-MM-DD')
          j.closeDate = date ? moment(date).format('YYYY-MM-DD') : ""
          j.totalPay = setCurrencyFormat(j.total)
          j.tipAmountPay = setCurrencyFormat(j.tipAmount)
          j.employeeName = j.employee?.name ? `${j.employee.name} ${j.employee.lastName}` : null
        })
          return dataOrder.data
        } catch (error) {
          console.log(error, "error")
          this.loading = false
        }
      },
    },
    
  }
</script>