export const ocupacionHeaders = [
  {
    text: 'Mes',
    sortable: false,
    value: 'month',
    class: 'primary--text',
  },
  {
    text: 'Porcentaje de habitaciones ocupadas',
    sortable: false,
    value: 'percentage',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Habitaciones ocupadas',
    sortable: false,
    value: 'count',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Ingresos por hospedaje',
    sortable: false,
    value: 'total',
    class: 'primary--text',
    align: 'right',
  },
]

export const dashboarHeaders = [
  {
    text: 'REF',
    class: 'primary--text',
    value: 'ref',
  },
  {
    text: 'Contacto',
    class: 'primary--text',
    value: 'wayContactName',
  },
  {
    text: 'Check-in',
    class: 'primary--text',
    value: 'dateStart',
  },
  {
    text: 'Check-out',
    class: 'primary--text',
    value: 'dateEnd',
  },
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'user.name',
  },
  {
    text: 'Tipologia',
    class: 'primary--text',
    value: 'place.typology.name',
  },
  {
    text: 'Habitacion',
    class: 'primary--text',
    value: 'place.name',
  },
  {
    text: 'Status',
    class: 'primary--text',
    value: 'status',
  },
]

export const seguimientoHeaders = [
  {
    text: 'REF',
    class: 'primary--text',
    value: 'ref',
  },
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'user.name',
  },
  {
    text: 'Habitacion',
    class: 'primary--text',
    value: 'place.name',
  },
  {
    text: 'Status',
    class: 'primary--text',
    value: 'status',
  },
  {
    text: 'Noches',
    class: 'primary--text',
    value: 'nights',
  },
  {
    text: 'Rates',
    class: 'primary--text',
    value: 'rates.length',
  },
  {
    text: 'Rates por mes',
    class: 'primary--text',
    value: 'nightsByMonth.length',
  },
  {
    text: 'Total por mes',
    class: 'primary--text',
    value: 'nightsByMonthTotal',
  },
  {
    text: 'Total ordenes',
    class: 'primary--text',
    value: 'totalOrders',
  },
  {
    text: 'Total pagos',
    class: 'primary--text',
    value: 'totalPays',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'total',
  },
]

export const recaudoBookingHeaders = [
  {
    text: 'REF',
    class: 'primary--text',
    value: 'ref',
  },
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'user.name',
  },
  {
    text: 'Tipologia',
    class: 'primary--text',
    value: 'place.typology.name',
  },
  {
    text: 'Habitacion',
    class: 'primary--text',
    value: 'place.name',
  },
  {
    text: 'Estado',
    class: 'primary--text',
    value: 'status',
  },
  {
    text: 'Valor Mes',
    class: 'primary--text',
    value: 'monthAmount',
  },
  {
    text: 'Extras',
    class: 'primary--text',
    value: 'roomServices',
  },
  {
    text: 'Noches',
    class: 'primary--text',
    value: 'bookingNights',
  },
]

export const bookingHeaders = [
  {
    text: 'REF',
    class: 'primary--text',
    value: 'ref',
  },
  {
    text: 'Contacto',
    class: 'primary--text',
    value: 'wayContactName',
  },
  {
    text: 'Check-in',
    class: 'primary--text',
    value: 'dateStart',
  },
  {
    text: 'Check-out',
    class: 'primary--text',
    value: 'dateEnd',
  },
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'user.name',
  },
  {
    text: 'Tipologia',
    class: 'primary--text',
    value: 'place.typology.name',
  },
  {
    text: 'Habitacion',
    class: 'primary--text',
    value: 'place.name',
  },
  {
    text: 'Status',
    class: 'primary--text',
    value: 'status',
  },
  {
    text: 'Creada',
    class: 'primary--text',
    value: 'createdAt',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'total',
  },
  {
    text: 'Virtual',
    class: 'primary--text',
    value: 'isVirtual',
  },
]

export const comercialHeaders = [
  {
    text: 'Habitacion ',
    class: 'primary--text',
    value: 'name',
  },
  {
    text: '# Reservas ',
    class: 'primary--text',
    value: 'bookings.length',
  },
  {
    text: 'Vendido ',
    class: 'primary--text',
    value: 'totalSales',
  },
  { text: '', value: 'data-table-expand' },
]

export const ratesHeaders = [
  {
    text: 'Fecha ',
    class: 'primary--text',
    value: 'date',
  },
  {
    text: 'Precio',
    class: 'primary--text',
    value: 'totalRate',
  },
  {
    text: 'Cerrada',
    class: 'primary--text',
    value: 'closed',
  },
  
]

export const userHeaders = [
  {
    text: 'Nombre',
    class: 'primary--text',
    value: 'name',
  },
  {
    text: 'Email',
    class: 'primary--text',
    value: 'email',
  },
  {
    text: 'Documento',
    class: 'primary--text',
    value: 'documentID',
  },
  {
    text: 'Categoria',
    class: 'primary--text',
    value: 'category',
  },
]

export const clientHeaders = [
  {
    text: 'Nombre',
    class: 'primary--text',
    value: 'name',
  },
  {
    text: 'Email',
    class: 'primary--text',
    value: 'email',
  },
  {
    text: 'Documento',
    class: 'primary--text',
    value: 'documentID',
  },
  {
    text: 'Categoria',
    class: 'primary--text',
    value: 'category',
  },
  {
    text: 'Creado',
    class: 'primary--text',
    value: 'createdAt',
  },
]

export const orderHeaders = [
  {
    text: 'Habitacion',
    class: 'primary--text',
    value: 'booking.place.name',
  },
  {
    text: 'Tienda',
    class: 'primary--text',
    value: 'business.name',
  },
  {
    text: 'Nombre',
    class: 'primary--text',
    value: 'name',
  },
  {
    text: 'Creada',
    class: 'primary--text',
    value: 'createdAt',
  },
  {
    text: 'Empleado',
    class: 'primary--text',
    value: 'employee',
  },
  {
    text: 'Subtotal',
    class: 'primary--text',
    value: 'subtotal',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'total',
  },
  {
    text: 'Virtual',
    class: 'primary--text',
    value: 'isVirtual',
  },
]

export const payHeaders = [
  {
    text: 'Autorización',
    class: 'primary--text',
    value: 'authorization',
  },
  {
    text: 'Balance',
    class: 'primary--text',
    value: 'isForLodging',
  },
  {
    text: 'Tipo',
    class: 'primary--text',
    value: 'type',
  },
  {
    text: 'Metodo',
    class: 'primary--text',
    value: 'paymentMethod',
  },
  {
    text: 'Creada',
    class: 'primary--text',
    value: 'createdAt',
  },
  {
    text: 'Empleado',
    class: 'primary--text',
    value: 'employee',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'amount',
  },
]

export const orderItemHeaders = [
  {
    text: 'Nombre',
    class: 'primary--text',
    value: 'name',
  },
  {
    text: 'Type',
    class: 'primary--text',
    value: 'type',
  },
  {
    text: '#',
    class: 'primary--text',
    value: 'count',
  },
  {
    text: 'Unidad',
    class: 'primary--text',
    value: 'countType',
  },
  {
    text: 'Cortesia',
    class: 'primary--text',
    value: 'isFree',
  },
  {
    text: 'Subtotal',
    class: 'primary--text',
    value: 'priceSubtotal',
  },
  {
    text: 'total',
    class: 'primary--text',
    value: 'priceTotal',
  },
]

export const PosSalesHeaders = [
  {
    text: 'N°',
    class: 'primary--text',
    value: 'invoice',
  },
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'client',
  },
  {
    text: 'Empleado',
    class: 'primary--text',
    value: 'employeeName',
  },
  {
    text: 'Negocio',
    class: 'primary--text',
    value: 'business.name',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'totalPay',
  },
  {
    text: 'Propina',
    class: 'primary--text',
    value: 'tipAmountPay',
  },
  {
    text: 'Descuento',
    class: 'primary--text',
    value: 'discountAmount',
  },
  {
    text: '% Desc',
    class: 'primary--text',
    value: 'percDiscount',
  },
  {
    text: 'Ref Siigo',
    class: 'primary--text',
    value: 'siigo_ref',
  },
  {
    text: 'Met. pago',
    class: 'primary--text',
    value: 'methodPay.method',
  },
  {
    text: 'Personas',
    class: 'primary--text',
    value: 'personOrder',
  },
  {
    text: 'Fecha de creación',
    class: 'primary--text',
    value: 'createdAt',
  },
  {
    text: 'Hora de creación',
    class: 'primary--text',
    value: 'createdHour',
  },
  {
    text: 'Fecha de cierre',
    class: 'primary--text',
    value: 'closeDate',
  },
  {
    text: 'Hora de cierre',
    class: 'primary--text',
    value: 'closeHour',
  },
]

export const forecastsHeaders = [
  {
    text: 'Fecha',
    class: 'primary--text',
    value: 'date',
  },
  {
    text: 'Habitaciones',
    class: 'primary--text',
    value: 'roomCounts',
  },
  {
    text: 'Llegadas',
    class: 'primary--text',
    value: 'comings',
  },
  {
    text: 'Salidas',
    class: 'primary--text',
    value: 'leaves',
  },
  {
    text: 'En Casa',
    class: 'primary--text',
    value: 'inHouse',
  },
  {
    text: 'Ocupadas',
    class: 'primary--text',
    value: 'occupieds',
  },
  {
    text: 'Bloqueadas',
    class: 'primary--text',
    value: 'roomBlockeds',
  },
  {
    text: 'Total hospedaje',
    class: 'primary--text',
    value: 'total',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'total',
  },
]

export const serviceOrderHeaders = [
  {
    text: 'Nro Orden',
    sortable: false,
    value: 'service.invoice',
    class: 'primary--text',
  },
  {
    text: 'Servicio',
    sortable: false,
    value: 'service',
    class: 'primary--text',
  },
  {
    text: 'Cliente',
    sortable: false,
    value: 'user',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Sub Total',
    sortable: false,
    value: 'subtotal',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Tax',
    sortable: false,
    value: 'taxAmount',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Total',
    sortable: false,
    value: 'total',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Cargo adicional',
    sortable: false,
    value: 'extra',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Crédito',
    sortable: false,
    value: 'credit',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Descuento',
    sortable: false,
    value: 'discount',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Total a pagar',
    sortable: false,
    value: 'totalPay',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Status',
    sortable: false,
    value: 'status',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Empleado',
    sortable: false,
    value: 'employee',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Booking',
    sortable: false,
    value: 'booking.ref',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Método de pago',
    sortable: false,
    value: 'methodPay',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: 'Fecha',
    sortable: false,
    value: 'createdAt',
    class: 'primary--text',
    align: 'right',
  },
]

export const tipHeaders = [
  {
    text: 'Trabajador',
    value: 'employee',
    class: 'primary--text',
    align: 'left',
  },
  {
    text: 'Email',
    value: 'email',
    class: 'primary--text',
    align: 'left',
  },
  {
    text: 'Monto',
    sortable: false,
    value: 'amount',
    class: 'primary--text',
    align: 'left',
  },
  {
    text: 'Cliente',
    value: 'client',
    class: 'primary--text',
    align: 'left',
  },
  {
    text: 'Nota',
    value: 'note',
  },
  {
    text: 'Fecha',
    value: 'date',
    class: 'primary--text',
    align: 'left',
  },
]

export const tipHeaders2 = [
  {
    text: 'Trabajador',
    value: 'employee',
    class: 'primary--text',
    align: 'left',
  },
  {
    text: 'Email',
    value: 'employee.email',
    class: 'primary--text',
    align: 'left',
  },
  {
    text: 'Monto',
    sortable: false,
    value: 'total',
    class: 'primary--text',
    align: 'left',
  },
]

export const PosGroupHeaders = [
  {
    text: 'N° total de ordenes',
    class: 'primary--text',
    value: 'count',
  },
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'client',
  },
  {
    text: 'Correo',
    class: 'primary--text',
    value: 'email',
  },
  {
    text: 'Subtotal',
    class: 'primary--text',
    value: 'subtotal',
  },
  {
    text: 'Impuestos',
    class: 'primary--text',
    value: 'tax',
  },
  {
    text: 'Propinas',
    class: 'primary--text',
    value: 'tip',
  },
  {
    text: 'Descuentos',
    class: 'primary--text',
    value: 'discount',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'total',
  },
]

export const PosPopularityHeaders = [
  {
    text: 'Negocio',
    class: 'primary--text',
    value: 'business.name',
    sortable: false
  },
  {
    text: 'Nombre',
    class: 'primary--text',
    value: 'name',
    sortable: false
  },
  // {
  //   text: 'Centro de Costo',
  //   class: 'primary--text',
  //   value: 'costCenterName',
  // },
  {
    text: 'Costo actual por unidad',
    class: 'primary--text',
    value: 'cost',
    sortable: false
  },
  {
    text: 'Precio actual',
    class: 'primary--text',
    value: 'priceTotal',
    sortable: false
  },
  {
    text: 'Cantidad vendida',
    class: 'primary--text',
    value: 'totalQty',
    sortable: false
  }
]

export const PosGiftHeaders = [
  {
    text: 'Negocio',
    class: 'primary--text',
    value: 'business.name',
    sortable: false
  },
  {
    text: 'Nombre',
    class: 'primary--text',
    value: 'name',
    sortable: false
  },
  // {
  //   text: 'Centro de Costo',
  //   class: 'primary--text',
  //   value: 'costCenterName',
  // },
  {
    text: 'Costo actual por unidad',
    class: 'primary--text',
    value: 'cost',
    sortable: false
  },
  {
    text: 'Precio actual',
    class: 'primary--text',
    value: 'priceTotal',
  },
  {
    text: 'Cantidad de cortesías',
    class: 'primary--text',
    value: 'totalQty',
  }
]

export const PosDetailsHeaders = [
  {
    text: 'Negocio',
    class: 'primary--text',
    value: 'business.name',
  },
  {
    text: 'N° de orden',
    class: 'primary--text',
    value: 'order.invoice',
  },
  {
    text: 'Estado orden',
    class: 'primary--text',
    value: 'status',
  },
  {
    text: 'Item',
    class: 'primary--text',
    value: 'item.name',
  },
  {
    text: 'Distribución de Producto',
    class: 'primary--text',
    value: 'costCenterName',
    sortable: false
  },
  {
    text: 'Cantidad',
    class: 'primary--text',
    value: 'qty',
  },
  {
    text: 'Costo actual por unidad',
    class: 'primary--text',
    value: 'cost',
    sortable: false
  },
  {
    text: 'Precio unidad',
    class: 'primary--text',
    value: 'price',
  },
  {
    text: 'Cupón',
    class: 'primary--text',
    value: 'coupon',
  },
  {
    text: 'Cupón Monto',
    class: 'primary--text',
    value: 'couponAmount',
  },
  {
    text: 'Tipo impuesto',
    class: 'primary--text',
    value: 'item.taxType',
  },
  {
    text: 'Impuesto unidad',
    class: 'primary--text',
    value: 'taxAmount',
  },
  {
    text: 'Monto por adición',
    class: 'primary--text',
    value: 'extras',
  },
  {
    text: 'Cortesía',
    class: 'primary--text',
    value: 'gift',
  },
  {
    text: 'Evento',
    class: 'primary--text',
    value: 'order.event',
  },
  {
    text: 'Fecha de creación',
    class: 'primary--text',
    value: 'createdAt',
  },
  {
    text: 'Nota',
    class: 'primary--text',
    value: 'note',
  },
]

export const PosPayHeaders = [
  {
    text: 'Negocio',
    class: 'primary--text',
    value: 'business.name',
  },
  {
    text: 'Empleado',
    class: 'primary--text',
    value: 'employeeName',
  },
  {
    text: 'Monto',
    class: 'primary--text',
    value: 'totalPay',
  },
  {
    text: 'Voucher',
    class: 'primary--text',
    value: 'split.boucher',
  },
  {
    text: 'N° orden',
    class: 'primary--text',
    value: 'invoice',
  },
  {
    text: 'Met. pago',
    class: 'primary--text',
    value: 'methodPay.text',
  },
  {
    text: 'Ref Siigo',
    class: 'primary--text',
    value: 'siigo_ref',
  },
  {
    text: 'Fecha de creación',
    class: 'primary--text',
    value: 'createdAt',
  },
  {
    text: 'Fecha de cierre',
    class: 'primary--text',
    value: 'updatedAt',
  },
]

export const activeBalancesHeaders = [
  {
    text: 'Tipologia',
    class: 'primary--text',
    value: 'place.typology.name',
  },
  {
    text: 'Habitacion',
    class: 'primary--text',
    value: 'place.name',
  },
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'user.name',
  },
  {
    text: 'Balance hospedaje',
    class: 'primary--text',
    value: 'hostBalance',
  },
  {
    text: 'Balance extra',
    class: 'primary--text',
    value: 'extraBalance',
  },
  {
    text: 'Balance total',
    class: 'primary--text',
    value: 'balance',
    align: 'right',
  },
]

export const reviewHeaders = [
  {
    text: '',
    sortable: false,
    value: '_id',
    class: 'primary--text',
    align: ' d-none'
  },
  {
    text: 'Review',
    sortable: false,
    value: 'relatedData',
    class: 'primary--text',
  },
  {
    text: 'Cliente',
    sortable: false,
    value: 'user',
    class: 'primary--text',
  },
  {
    text: 'Fecha',
    sortable: false,
    value: 'createdAt',
    class: 'primary--text',
    align: 'right',
  },
  {
    text: '',
    sortable: false,
    value: 'data-table-expand',
    class: 'primary--text',
  },
]

export const PosOrderGroupHeaders = [
  {
    text: 'Cliente',
    class: 'primary--text',
    value: 'client',
  },
  {
    text: 'Total',
    class: 'primary--text',
    value: 'total',
  }
]

export const PosDetailGrossMargin = [
  {
    text: 'Negocio',
    class: 'primary--text',
    value: 'business.name',
  },
  {
    text: 'Item',
    class: 'primary--text',
    value: 'name',
  },
  {
    text: 'Cantidad',
    class: 'primary--text',
    value: 'totalQty',
  },
  {
    text: 'Costo actual por unidad',
    class: 'primary--text',
    value: 'cost',
  },
  {
    text: 'Ingreso real',
    class: 'primary--text',
    value: 'totalReal',
  },
  {
    text: 'CMV (%)',
    class: 'primary--text',
    value: 'cmv',
  },
  {
    text: 'Margen Bruto ($)',
    class: 'primary--text',
    value: 'totalGrossMargin',
  }
]

export const PosStatusTimeHeaders = [
  {
    text: 'Negocio',
    class: 'primary--text',
    value: 'business.name',
  },
  {
    text: 'N° orden',
    class: 'primary--text',
    value: 'invoice',
  },
  {
    text: 'En preparación',
    class: 'primary--text',
    value: 'time1.text',
  },
  {
    text: 'Listo para entregar',
    class: 'primary--text',
    value: 'time2.text',
  },
  {
    text: 'Cerrado',
    class: 'primary--text',
    value: 'time3.text',
  },
  {
    text: 'Abierta - Cerrado',
    class: 'primary--text',
    value: 'time4.text',
  },
  // {
  //   text: 'Met. pago',
  //   class: 'primary--text',
  //   value: 'methodPay.text',
  // },
  // {
  //   text: 'Fecha de creación',
  //   class: 'primary--text',
  //   value: 'createdAt',
  // },
  // {
  //   text: 'Fecha de cierre',
  //   class: 'primary--text',
  //   value: 'updatedAt',
  // },
]
export const movementsPlaceReservationHeaders = [
  {
    value: 'by_client_app',
    text: 'APP',
    class: 'primary--text',
  },
  {
    value: 'createdAt',
    text: 'Fecha',
    class: 'primary--text',
  },
  {
    value: 'user',
    text: 'Cliente',
    class: 'primary--text',    
  },
  {
    value: 'place',
    text: 'Espacio',
    class: 'primary--text',
  },
  {
    value: 'reservation',
    text: 'Fecha Reserva',
    class: 'primary--text',
  },
  {
    value: 'recurrence',
    text: 'Recurrencia',
    class: 'primary--text',
  },
  {
    value: 'payMethod',
    text: 'Método de Pago',
    class: 'primary--text',
  },
  {
    value: 'subtotal',
    text: 'Sub Total',
    class: 'primary--text',
    callback: (price) => {
      return `<label style= color:${price > 0? 'green':'red'};>${setCurrencyFormat(price)}</label>`
    },
  },  
  {
    value: 'discount_total',
    text: 'Descuento',
    class: 'primary--text',
    callback: (price) => {      
      return `<label style= color:${price > 0? 'green':'red'};>${setCurrencyFormat(price)}</label>`
    },
  },
  {
    value: 'total',
    text: 'Total',
    class: 'primary--text',
    callback: (price) => {
      return `<label style= color:${price > 0? 'green':'red'};>${setCurrencyFormat(price)}</label>`
    },
  }
]
export const movementsQRHeaders = [
  {
    value: 'by_client_app',
    text: 'APP',
    class: 'primary--text',
  },
  {
    value: 'user',
    text: 'Cliente',
    class: 'primary--text',
  },
  {
    value: 'eventTitle',
    text: 'Evento',
    class: 'primary--text',
  },
  {
    value: 'payMethod',
    text: 'Método de Pago',
    class: 'primary--text',
  },
  {
    value: 'count',
    text: 'Tickets',
    class: 'primary--text',
  },
  {
    value: 'subtotal',
    text: 'Sub Total',
    class: 'primary--text',
  },
  {
    value: 'total',
    text: 'Total',
    class: 'primary--text',
  }
]